import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CContainer,
	CHeader,
	CHeaderDivider,
	CImage,
	CForm,
	CRow,
	CCol,
	CButton,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
	CFormSelect,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubperawatjadwal = (props) => {
	const { showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uEfekapp				= useSelector(state => state.effectApp);
	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangfilterArr		= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
		: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
	
	const vFilterview			= uKeywordObj.filter_perawatjadwal || {};
	const [uInpCabangid,setInpCabangid]	= React.useState(vFilterview.cabang_id||uTokenObj.user_cabangid);
	const [uInpTglantri,setInpTglantri]	= React.useState(vFilterview.tgl_antri||UFunc.DbDate());

	const hdlKDowntglantri = (_EV) => {
		if(_EV.which==13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		const vTglantriDT	= UFunc.toDate(uInpTglantri);
		if(UFunc.DateIsMundur(vTglantriDT)) {
			showToast("<b>Tanggal Antrian Sudah Expired</b>.."+
				"<br/>Mohon Diperbaiki !"+
				"")
			return;
		}
		if(parseInt(uInpCabangid) <= 0) {
			showToast("[] UNDEFINED.."); return
		}

		//---SAVE_KEYWORD--/
		uKeywordObj.filter_perawatjadwal = {
			cabang_id: uInpCabangid,
			tgl_antri: uInpTglantri,
		}
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.filter_perawatjadwal = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpTglantri(UFunc.DbDate());
	    	setInpCabangid(uTokenObj.user_cabangid);
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if(uInpTglantri !== UFunc.DbDate()
			|| uInpCabangid !== uTokenObj.user_cabangid
		) {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	//console.log("Headersubperawatjadwal - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<CForm>
			<CInputGroup
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			{uDatacabangArr.length > 1 && (
				<>
				{(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && (
				<CFormSelect size="sm"
					value={uInpCabangid} 
					onChange={(e) => setInpCabangid(e.target.value)} 
					id="inpcabangid">
					{uCabangfilterArr.map((vItems,vKeys)=>{
						return (<option value={vItems.id} key={vKeys}>{vItems.cabang_nama.toUpperCase()}</option>)
					})}
				</CFormSelect>
				)}
				</>
			)}
			<CFormInput size="sm" type="date"
				value={uInpTglantri} 
				onChange={(e) => setInpTglantri(e.target.value)} 
				onKeyDown={(e) => hdlKDowntglantri(e)} 
				id="inptglantri"/>
			<CButton size="sm" 
				color="light" 
				className="border"
				onClick={()=>hdlKlikCari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass" className="text-dark" />
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="ms-1 d-none d-md-block">
			<CTooltip className="d-none d-lg-block" content={"--"+(uBahasaObj.menus_antrianpasien||"Antrian Pasien")}>
			<CLink onClick={()=>uNavigate("/subantrian/antrianpasien")} className="classcursorpointer">
				<CImage src={pjson.homepage+"api/images/menus_antrianpasien.png"} height={32}/>
			</CLink>
			</CTooltip>

			<span className="mx-1 d-none">&middot;</span>

			<CTooltip content={"--"+(uBahasaObj.menus_antrianruang||"Antrian Ruangan")}>
			<CLink onClick={()=>uNavigate("/subantrian/antrianruang")} className="d-none classcursorpointer">
				<CImage src={pjson.homepage+"api/images/menus_antrianruang.png"} height={32}/>
			</CLink>
			</CTooltip>
		</div>
		</>
	)
}

export default Headersubperawatjadwal
